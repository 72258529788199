<template>
  <b-card>
    <h2 class="mb-2">Dados da empresa</h2>
    <b-overlay
      :show="isLoading"
      opacity="0.70"
      variant="transparent"
      rounded="sm"
    >
      <b-row>
        <b-col lg="4">
          <table class="mt-2 mt-xl-0 w-98">
            <tr>
              <th class="pb-50">
                <feather-icon icon="CreditCardIcon" class="mr-75" />
                <span class="font-weight-bold">CNPJ:</span>
              </th>
              <td class="pb-50 text-capitalize font-weight-bold">
                {{ contractDetails ? contractDetails.cnpj : '' }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon icon="HomeIcon" class="mr-75" />
                <span class="font-weight-bold">Empresa:</span>
              </th>
              <td class="pb-50 text-capitalize font-weight-bold">
                {{ contractDetails ? contractDetails.company_name : '' }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon icon="HomeIcon" class="mr-75" />
                <span class="font-weight-bold">Nome Fantasia:</span>
              </th>
              <td class="pb-50 text-capitalize font-weight-bold">
                {{ contractDetails ? contractDetails.fantasy_name : '' }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon icon="GlobeIcon" class="mr-75" />
                <span class="font-weight-bold">Google Classroom:</span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ contractDetails ? contractDetails.classroom_domain : '' }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon icon="ListIcon" class="mr-75" />
                <span class="font-weight-bold">Categoria:</span>
              </th>
              <td class="pb-50" v-if="contractDetails && contractDetails.category == 'publica'">Empresa pública</td>
              <td class="pb-50" v-else>Empresa privada</td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon icon="PhoneIcon" class="mr-75" />
                <span class="font-weight-bold">Telefone:</span>
              </th>
              <td class="pb-50">
                {{ contractDetails ? contractDetails.phone : '' }}
              </td>
            </tr>
          </table>
        </b-col>
        <b-col g="4">
          <table class="mt-2 mt-xl-0 w-98">
            <tr>
              <th class="pb-50">
                <feather-icon icon="CalendarIcon" class="mr-75" />
                <span class="font-weight-bold">Data de cadastro:</span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ contractDetails.created_at | dateFormat }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon icon="CalendarIcon" class="mr-75" />
                <span class="font-weight-bold">Data da última atualização:</span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ contractDetails.updated_at | dateFormat }}
              </td>
            </tr>
          </table>
        </b-col>
      </b-row>
      <hr />
      <b-tabs content-class="mt-2">
        <b-tab active>
          <template #title>
            <span>Assinaturas</span>
          </template>
          <div>
            <b-row>
              <b-col>
                <h3 class="mt-2">Lista de Assinaturas</h3>
              </b-col>
            </b-row>
            <b-table-simple
              striped
              stacked="md"
              class="mt-2 responsive text-center"
              id="tableBook"
            >
              <b-thead>
                <b-tr class="text-center">
                  <b-th>Data criação</b-th>
                  <b-th>Data expiração</b-th>
                  <b-th>Número de licenças</b-th>
                  <b-th>Situação</b-th>
                  <b-th>Ações</b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr :key="indextr" v-for="(tr, indextr) in contractDetails.contracts">
                  <b-td>
                    {{ tr.created_at | dateFormat }}
                  </b-td>
                  <b-td>
                    {{ tr.expiration_date | dateFormat }}
                  </b-td>
                  <b-td>
                    {{ tr.number_of_licences }}
                  </b-td>
                  <b-td>
                    <b-badge
                      v-if="Date.now() > new Date(tr.expiration_date)"
                      pill
                      variant="light-danger"
                    >
                      Expirado 
                    </b-badge>
                    <b-badge
                      v-else
                      pill
                      variant="light-success"
                    >
                      Ativo 
                    </b-badge>
                  </b-td>
                  <b-td>
                    <b-button
                      v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                      variant="flat-dark"
                      class="btn-icon rounded-circle"
                      :to="{ path: '/contract/' + tr.id }"
                    >
                      <feather-icon icon="EyeIcon" />
                    </b-button>
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
            <b-row class="mt-1">
              <b-col md="10">
                <b-row>
                  <b-col md="5">
                    Exibindo {{ perPage }} de {{ totalRows }} registros
                  </b-col>
                </b-row>
              </b-col>
              <b-col md="2">
                <b-pagination
                  @change="handlePageChange"
                  v-model="currentPage"
                  :per-page="perPage"
                  :total-rows="totalRows"
                  aria-controls="tableBook"
                  class="mt-1"
                />
              </b-col>
            </b-row>
          </div>
        </b-tab>
        <b-tab>
          <template #title>
            <span>Gestores</span>
          </template>
          <div>
            <b-row>
              <b-col lg="10">
                <h3 class="mt-2">Lista de gestores</h3>
              </b-col>
              <b-col>
                <b-button @click="addNew" block variant="relief-primary" size="sm" v-b-toggle.sidebar_create>
                  Novo Gestor
                  <feather-icon icon="UserIcon" />
                </b-button>
              </b-col>
            </b-row>
            <b-table-simple
              striped
              stacked="md"
              class="mt-2 responsive text-center"
              id="tableBook"
              v-if="companyDetails && companyDetails.users"
            >
              <b-thead>
                <b-tr class="text-center">
                  <b-th>Nome</b-th>
                  <b-th>Usuário de Acesso</b-th>
                  <b-th>Email</b-th>
                  <b-th>Data de Cadastro</b-th>
                  <b-th>Ação</b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr :key="indextr" v-for="(tr, indextr) in companyDetails.users">
                  <b-td>
                    {{tr.name}}
                  </b-td>
                  <b-td>
                    {{tr.username}}
                  </b-td>
                  <b-td>
                    {{tr.email}}
                  </b-td>
                  <b-td>
                    {{tr.created_at | dateFormat}}
                  </b-td>
                  <b-td>
                    <b-button
                      v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                      variant="flat-dark"
                      class="btn-icon rounded-circle"
                      v-b-toggle.sidebar_create
                      @click="showUser(tr.id)"
                    >
                      <feather-icon icon="Edit2Icon" />
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                      variant="flat-dark"
                      class="btn-icon rounded-circle"
                      @click="showUser(tr.id, isDelete = true)"
                      v-b-modal.modal-delete
                    >
                      <feather-icon icon="Trash2Icon" />
                    </b-button>
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
            <create-edit-company-admin :key="sidebarData.id" :dataProp="sidebarData" @fetch-details="showCompany"/>
            <b-row class="mt-1">
              <b-col md="10">
                <b-row>
                  <b-col md="5">
                    Exibindo {{ perPage }} de {{ totalRows }} registros
                  </b-col>
                </b-row>
              </b-col>
              <b-col md="2">
                <b-pagination
                  @change="handlePageChange"
                  v-model="currentPage"
                  :per-page="perPage"
                  :total-rows="totalRows"
                  aria-controls="tableBook"
                  class="mt-1"
                />
              </b-col>
            </b-row>
            <!-- Modal Delete -->
            <b-modal
              id="modal-delete"
              ok-variant="danger"
              modal-class="modal-danger"  
              ref="modal-delete"
              centered
              title="Deletar"
            >
              <b-card-text  v-if="userData != null">
                <p>Você está preste a deletar este gestor do sistema: <b>{{ userData.username }}</b></p>
                Por favor, confirme a exclusão antes de prosseguir. 
                <b-form-checkbox
                  id="checkbox-1"
                  @change="newDeleteStatus"
                  v-model="DeleteStatus"
                  name="checkbox-1"
                  value="accepted"
                >
                  Concordo em excluir esta empresa
                </b-form-checkbox>  
              </b-card-text>
              <template #modal-footer>
                <b-col>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    class="mt-2"
                    variant="outline-danger"
                    block
                    :disabled="DeleteStatus == false"
                    @click="deleteUser(userData.id)"
                  >
                    Deletar
                  </b-button>
                </b-col>
              </template>
            </b-modal>
          </div>
        </b-tab>
      </b-tabs>
    </b-overlay>
  </b-card>
</template>
<script>
/* eslint-disable */
import { BCard } from "bootstrap-vue";
import { mapActions, mapState } from "vuex";
import Ripple from "vue-ripple-directive";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import CreateEditCompanyAdmin from "./CreateEditCompanyAdmin.vue";

export default {
  components: {
    BCard,
    vSelect,
    CreateEditCompanyAdmin
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      search: null,
      perPage: 10,
      currentPage: 1,
      totalRows: null,
      isLoading: true,
      isLoadingStore: false,

      count: 1,
      DeleteStatus: false,

      sidebarData: {
        id: null,
        email: null,
        name: null,
        label: null,
        username: null
      },

      userData: {
        id: null,
        username: null
      },

      contractDetails: {},

      companyDetails: null,

      csrf: document.head.querySelector('meta[name="csrf-token"]') ? document.head.querySelector('meta[name="csrf-token"]').content : '',

      perPageOptions: [
        { value: 10, text: "10" },
        { value: 15, text: "15" },
        { value: 20, text: "20" },
      ],

      optionsCategories: [
        { value: 'student', text: 'Aluno' },
        { value: 'teacher', text: 'Professor' }
      ],
    }
  },
  computed: {
    ...mapState("contracts", ["company_contracts"]),
    ...mapState("companies", ["companies"]),
    ...mapState("user", ["users"]),
  },
  created(){
    this.showContract()
    this.showCompany()
     if (! this.csrf) {
      console.warn('The CSRF token is missing. Ensure that the HTML header includes the following: <meta name="csrf-token" content="{{ csrf_token() }}">');
    }
  },
  methods: {
    ...mapActions("contracts", ["fetchContracts", "find"]),

    addNew() {
      this.sidebarData.id = null; 
      this.count++;
    },

    showContract(paginated = false, page = 1){
      this.isLoading = true
      let company_id = this.$route.params.company_id;
      if(localStorage.getItem('company_id')){
        company_id = localStorage.getItem('company_id')
      }
      this.$store
        .dispatch("contracts/fetchContractsByCompany", {
          company_id: company_id ? company_id : null,
          paginate: paginated,
          page: page,
          generic: this.search,
          per_page: this.perPage
        })
        .then((response) => {
          this.contractDetails = response
           this.totalRows = response.total
        }) 
        .finally(() => {
          this.isLoading = false
        })
    },

    showUser(id, isDelete = false){
      this.$store
        .dispatch("users/fetchDetails", {
          users_id: id
        })
        .then((response) => {
          if(isDelete == true){
            this.userData.id = response.id,
            this.userData.username = response.username
          }else{
            this.count++;
            this.sidebarData.id =  response.id,
            this.sidebarData.email =  response.email,
            this.sidebarData.name =  response.name,
            this.sidebarData.label =  response.label,
            this.sidebarData.username =  response.username
          }          
        })
        .finally(() => {
        })
    },

    newDeleteStatus(value){
      this.DeleteStatus = value
    },

    deleteUser(id){
      this.isLoading = true;
      let data = id
      this.$store
        .dispatch("companies/deleteCompanyAdmin", data)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Excluído com sucesso!',
              icon: 'Trash2Icon',
              text: 'Operação executada com sucesso.',
              variant: 'danger'
            }
          })
          this.isLoading = false
          this.DeleteStatus = false
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          this.userData = {}
          this.showCompany()
          this.$refs['modal-delete'].hide()
        })
    },
    fetchCompanies(paginated =false, page = 1){
      this.isLoading = true
      this.$store
        .dispatch("companies/fetchCompanies", {})
        .then((response) => {
          this.totalRows = response.total
        })
        .catch((err) => {
          console.error(err)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    showCompany(paginated = false, page = 1){
      this.isLoading = true
       let company_id = this.$route.params.company_id;
      if(localStorage.getItem('company_id')){
        company_id = localStorage.getItem('company_id')
      }
      this.$store
        .dispatch("companies/fetchDetails", {
          company_id: company_id,
          paginate: paginated,
          page: page,
          generic: this.search,
          per_page: this.perPage
        })
        .then((response) => {
          this.companyDetails = response
           this.totalRows = response.total
        }) 
        .finally(() => {
          this.isLoading = false
        })
    },

    handlePageChange(value) {
      //this.showBook(true, value);
    },
  },
  watch: {
    perPage(){
      //this.showBook()
    }
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.bg-color-avatar {
  background: none !important;
}
</style>
