<template>
  <div>
    <b-sidebar
      id="sidebar_create"
      ref="sidebar_create"
      no-close-on-backdrop
      no-close-on-esc
      shadow
      right
      bg-variant="white"
      backdrop
      :visible=showSidebar
      :title="Object.entries(this.dataProp).length === 0 ? 'Cadastrar gestor' : 'Editar gestor'"
    >
      <div>
        <hr />
        <b-overlay :show="isLoadingStore" opacity="0.70" rounded="sm">
          <b-form method="POST">
            <validation-observer ref="simpleRules">
              <b-form-group
                label="Nome:"
                label-for="i-name-user"
                class="pl-1 pr-1"
              >
                <validation-provider
                  #default="{ errors }"
                  name="nome"
                  rules="required"
                >
                  <b-form-input
                    id="name-user"
                    v-model="dataUser.name"
                    placeholder="Nome"
                    name="i-name-user"
                    class="form-control"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                label="CPF (Usuário):"
                label-for="i-cpf-company-admin"
                class="pl-1 pr-1"
              >
                <validation-provider
                  #default="{ errors }"
                  name="CPF"
                  rules="required"
                >
                  <b-form-input
                    id="cpf"
                    v-model="dataUser.cpf"
                    placeholder="123.456.789-01"
                    v-mask="'###.###.###-##'"
                    name="i-cpf-company-admin"
                    class="form-control"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                label="Email:"
                label-for="i-email-company-admin"
                class="pl-1 pr-1"
              >
                <validation-provider
                  #default="{ errors }"
                  name="email"
                  rules="required|email"
                >
                  <b-form-input
                    id="email-company-admin"
                    v-model="dataUser.email"
                    placeholder="Ex: emailempresa@gmail.com"
                    name="email"
                    class="form-control"
                    type="email"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                label="Senha:"
                label-for="i-password"
                class="pl-1 pr-1"
                 v-if="dataProp.id == null"
              >
                <validation-provider
                  #default="{ errors }"
                  name="senha"
                  rules='required|password'
                >
                  <b-form-input
                    id="password"
                    v-model="dataUser.password"
                    placeholder="**************"
                    name="i-password"
                    class="form-control"
                    type="password"
                    autocomplete="off"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-row style="margin-right: 0rem !important;" class="mb-1">
                <b-col lg="3" sm="6" class="mr-1 ml-1">
                  <b-button
                    variant="relief-primary"
                    @click="storeContract((value = 1))"
                    :disabled="dataUser.name == null"
                  >
                    {{this.dataUser.id == null ? 'Salvar' : 'Editar'}}
                  </b-button>
                </b-col>
                <b-col>
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-danger"
                    @click="closeSidebar()"
                    block
                  >
                    Fechar
                  </b-button>
                </b-col>
              </b-row>
            </validation-observer>
          </b-form>
        </b-overlay>
      </div>
    </b-sidebar>
  </div>
</template>

<script>
/* eslint-disable */
import { BButton, BSidebar, VBToggle} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapActions, mapState } from "vuex";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {mask} from 'vue-the-mask'
import {
  required, email, confirmed, password,
} from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
  props: { 
    dataProp: {
      type: Object,
      default: () => {}
    },
  },
  components: {
    BButton,
    BSidebar,
    ValidationProvider,
    ValidationObserver
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple,
    mask,
  },
  data() {
    return {
      dataUser: {
        name: null,
        username: null,
        cpf: null,
        email: null,
        password: null
      },

      showSidebar: false,

      isLoadingStore: false,
      csrf: document.head.querySelector('meta[name="csrf-token"]') ? document.head.querySelector('meta[name="csrf-token"]').content : '',
    }
  },
  computed: {
    ...mapState("companies", ["companies"])
  },
  methods: {
    ...mapActions("companies", ["storeCompanyAdmin", "updateCompanyAdmin"]),
      storeContract(value) {
        this.$refs.simpleRules.validate().then(success => {
          if (success) {
            this.isLoadingStore = true
            if (this.dataProp.id === null) { 
              this.dataUser.username = this.dataUser.cpf;
              this.storeCompanyAdmin({
                ...this.dataUser,
                company_id: this.$route.params.company_id
              }).then(() => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Cadastrado com sucesso!',
                    icon: 'ThumbsUpIcon',
                    text: 'Operação executada com sucesso.',
                    variant: 'success'
                  }
                })
                this.initValues();
                this.closeSidebar();
              })
              .finally(() => {
                this.isLoadingStore = false
                this.$emit('fetch-details');
              })
            } else {
              this.dataUser.username = this.dataUser.cpf;
              this.updateCompanyAdmin(this.dataUser).then(() => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Atualizado com sucesso!',
                    icon: 'ThumbsUpIcon',
                    text: 'Operação executada com sucesso.',
                    variant: 'info'
                  }
                })
              })
              .finally(() => {
                this.isLoadingStore = false
                this.$emit('fetch-details');
                this.closeSidebar()
              })
            }
          }else{
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Erro de validação!',
                icon: 'AlertTriangleIcon',
                text: 'Por favor preencha todos os campos obrigatorios.',
                variant: 'danger'
              }
            })
          }
        })
        
    }, 
    initValues(){
      this.dataUser.id = null;
      this.dataUser.email = null;
      this.dataUser.name = null;
      this.dataUser.label = null;
       this.dataUser.cpf = null;
      this.dataUser.username = null;

      this.$nextTick(() => this.$refs.simpleRules.reset());
    },

    closeSidebar(){
      this.$refs['sidebar_create'].hide()
    }
  },
  watch: {
    dataProp: {
      // the callback will be called immediately after the start of the observation
      immediate: true, 
      handler (val, oldVal) {
        if (!val) return; 
        if (this.dataProp.id == null)  { 
          this.initValues(); 
          //this.$validator.reset(); 
        } else { 
          let { email, id, label, name, username } = this.dataProp;
          this.dataUser.id = id;
          this.dataUser.email = email;
          this.dataUser.name = name;
          this.dataUser.label = label;
          this.dataUser.cpf = username;
          this.dataUser.username = username;
          this.showSidebar = true;
        } 
      }
    }
  }
}
</script>